<template>
  <div>
    <v-col>
      <Stepper5 />
    </v-col>
    <div class="titulo">
      <Titulo Titulo="Registro de pasajeros" />
    </div>
    <div class="back">
      <router-link to="/paso4">
        <button>
          <Back />
        </button>
      </router-link>
    </div>
    <div class="subtitulo3">
      <Subtitulo3 subTitulo3="¿Quién viaja?" />
    </div>
    <InfoPasajero @eventCheckVuelta="updateDataVuelta" @eventBoletosIda="updateBoletoIda" :boletos_ida="boletos_ida" :data="data" />
    <InfoPasajeroVuelta v-if="value == 0" @eventBoletosVuelta="updateBoletoVuelta" :boletos_ida="boletos_ida" :boletos_vuelta="boletos_vuelta" ref="vuelta"/>
    <div class="segundo"></div>
    <DatosContacto :boletos_ida="boletos_ida" :boletos_vuelta="boletos_vuelta" />
  </div>
</template>

<style scoped lang="less">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@media screen and (max-width: 420px) {
  .titulo {
    font-family: "Montserrat", sans-serif;
    margin-left: -70px;
    font-size: 24px;
    margin-top: 5%;
  }
  .segundo {
    margin-top: -10%;
  }
  .subtitulo3 {
    font-family: "Montserrat", sans-serif;
    margin-top: -15px;
    font-size: 15px;
    margin-left: -65%;
  }
  #checkbox {
    margin-top: 0px;
    margin-left: 80px;
    width: 22px;
    height: 20px;
    border: 1px solid #1e5180;
  }
  .for {
    margin-top: 30px;
  }
  .back {
    margin-top: -12.5%;
    margin-left: 80%;
  }
}
@media screen and (min-width: 421px) and (max-width: 800px) {
  .titulo {
    font-family: "Montserrat", sans-serif;
    margin-left: -29%;
    font-size: 24px;
    margin-top: 5%;
  }
  .subtitulo3 {
    font-family: "Montserrat", sans-serif;
    margin-top: -5%;
    font-size: 15px;
    margin-left: -65%;
    padding-bottom: -50px;
  }
  #checkbox {
    margin-top: 0px;
    margin-left: 80px;
    width: 22px;
    height: 20px;
    border: 1px solid #1e5180;
  }
  .for {
    margin-top: 30px;
  }
  .back {
    margin-top: -11%;
    margin-left: 70%;
  }
}
@media screen and (min-width: 761px) and (max-width: 1200px) {
  .titulo {
    font-family: "Montserrat", sans-serif;
    margin-left: -62%;
    font-size: 24px;
    margin-top: -5%;
  }
  .subtitulo3 {
    font-family: "Montserrat", sans-serif;
    margin-top: -5%;
    font-size: 22px;
    margin-left: -70%;
  }
  #checkbox {
    margin-top: 0px;
    margin-left: 80px;
    width: 22px;
    height: 20px;
    border: 1px solid #1e5180;
  }
  .for {
    margin-top: 30px;
  }
  .back {
    margin-top: 1%;
    margin-left: -40%;
  }
  .segundo {
    margin-top: 5%;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1365px) {
  .titulo {
    font-family: "Montserrat", sans-serif;
    margin-left: -62%;
    font-size: 24px;
    margin-top: -2%;
  }
  .subtitulo3 {
    font-family: "Montserrat", sans-serif;
    margin-top: -4%;
    font-size: 24px;
    margin-left: -70%;
  }
  #checkbox {
    margin-top: 0px;
    margin-left: 80px;
    width: 22px;
    height: 20px;
    border: 1px solid #1e5180;
  }
  .for {
    margin-top: 30px;
  }
  .back {
    margin-top: 1%;
    margin-left: -40%;
  }
  .segundo {
    margin-top: 5%;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1439px) {
  .titulo {
    font-family: "Montserrat", sans-serif;
    margin-left: -62%;
    font-size: 24px;
    margin-top: -2%;
  }
  .subtitulo3 {
    font-family: "Montserrat", sans-serif;
    margin-top: 1%;
    font-size: 24px;
    margin-left: -69%;
  }
  #checkbox {
    margin-top: 0px;
    margin-left: 80px;
    width: 22px;
    height: 20px;
    border: 1px solid #1e5180;
  }
  .for {
    margin-top: 30px;
  }
  .back {
    margin-top: -3.5%;
    margin-left: -35%;
  }
  .segundo {
    margin-top: 5%;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1919px) {
  .titulo {
    font-family: "Montserrat", sans-serif;
    margin-left: -62%;
    font-size: 24px;
    margin-top: -2%;
  }
  .subtitulo3 {
    font-family: "Montserrat", sans-serif;
    margin-top: 1%;
    font-size: 24px;
    margin-left: -68.5%;
  }
  #checkbox {
    margin-top: 0px;
    margin-left: 80px;
    width: 22px;
    height: 20px;
    border: 1px solid #1e5180;
  }
  .for {
    margin-top: 30px;
  }
  .back {
    margin-top: -3.5%;
    margin-left: -35%;
  }
  .segundo {
    margin-top: 5%;
  }
}
@media screen and (min-width: 1920px) {
  .titulo {
    font-family: "Montserrat", sans-serif;
    margin-left: -62%;
    font-size: 24px;
    margin-top: -2%;
  }
  .subtitulo3 {
    font-family: "Montserrat", sans-serif;
    margin-top: 1%;
    font-size: 24px;
    margin-left: -67%;
  }
  #checkbox {
    margin-top: 0px;
    margin-left: 80px;
    width: 22px;
    height: 20px;
    border: 1px solid #1e5180;
  }
  .for {
    margin-top: 30px;
  }
  .back {
    margin-top: -2.5%;
    margin-left: -35%;
  }
  .segundo {
    margin-top: 5%;
  }
}
</style>


<script>
import { router } from '@/router'
import Stepper5 from "@/components/Stepper/Stepper5.vue";
import Titulo from "@/components/title.vue";
import Subtitulo3 from "@/components/SubTitle3.vue";
import InfoPasajero from "@/components/InfoPasajero.vue";
import InfoPasajeroVuelta from "@/components/InfoPasajeroVuelta.vue";
import AppButton from "@/components/ButtonPrimary.vue";
import Back from "@/components/Back.vue";
import DatosContacto from "@/components/DatosContactos.vue";
export default {
  name: "HomeView",
  props:{
    boletos_ida: {required:false},
    boletos_vuelta: {required:false}
  },
  data() {
    return {
      data: null,
      total_pasajeros: "",
      boleto_details: [],
      value: localStorage.getItem("value"),
      //boletos_ida: [],
    };
  },

  created(){
    const venta_exitosa = localStorage.getItem("venta_exitosa");
    if(venta_exitosa){
      this.$router.push("/");
    }
  },

  mounted() {
    Promise.all([this.obtenerPasajero() ]).then(function () {});
  },

  components: {
    Stepper5,
    Titulo,
    Subtitulo3,
    Back,
    AppButton,
    InfoPasajero,
    DatosContacto,
    InfoPasajeroVuelta
  },
 
  methods: {
    updateDataVuelta(data) {
      //this.boletos_ida = null;
      //this.boletos_ida = data;
      this.$refs.vuelta.validaChecks(data);
    },
    updateBoletoIda(data){
      this.$emit('eventBoletosIda', data);
    },
    updateBoletoVuelta(data){
      this.$emit('eventBoletosVuelta', data);
    },
    obtenerPasajero() {
      /*
      this.boleto_details.push({
        tipo_viaje: this.activeTab, 
        classActive: false,
        asiento: asiento.numero+' '+categoriaNombre,
        numero: asiento.numero,
        es_blanco: 0,
        categoria: asiento.categoria,
        precio_lista: asiento.precio_en_linea,
        monto_canje: asiento.monto_canje,
        id: asiento.id,
        descuento: undefined,
      });*/
      this.boleto_details.forEach(boleto => { });
      const pasajeros = parseInt(localStorage.getItem("total_pasajeros"));
      this.total_pasajeros = pasajeros;
      //console.log(this.total_pasajeros);
    },
    
    range(max) {
      return Array.from({ length: max }, (v, k) => k1);
    },
  },
};
</script>
