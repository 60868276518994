<template>
  <div class="container" v-if="boletosUpdate">
    <div v-for="(boleto, index) in boletos" :key="index + 1">
      <div v-show="boleto.check == false" class="infoPasajero">
        <h1>Pasajero {{ index + 1 }} vuelta</h1>
      </div>

      <h2 v-show="boleto.check == false">
        Asiento {{ boleto.asiento }} Categoría {{ boleto.categoria.nombre }}
      </h2>
      <v-row v-show="boleto.check == false">
        <div class="nombre">
          <input
            @input="saveToLocalStorage(); "
            type="Nombre"
            v-model="boleto.nombre_pasajero"
            placeholder="Nombre"
          />
        </div>

        <div class="columna">
          <h5>Edad</h5>
        </div>
        <input
          @input="saveToLocalStorage"
          type="edad"
          v-model="boleto.edad"
          placeholder="45"
        />
        <div class="descuento" v-if="descuento.length > 0">
          
          <select
            v-model="boleto.descuento"             
            @change="
              validarDescuento(index);
            "
            class="icon-rtl"
          >
         <option value=null disabled selected>Descuento</option>

            <option
              v-for="item in descuento"
              :key="item.id"
              :value="{
                nombre: item.attributes.nombre,
                id: item.id,
                mensaje_correo: item.attributes.mensaje_correo,
                tipo_descuento: item.attributes.tipo_descuento,
                valor: item.attributes.valor,
                tipo_valor: item.attributes.tipo_valor,
              }"
            >
              {{ item.attributes.nombre }}
            </option>
          </select>
         
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Titulo from "@/components/title.vue";
import Subtitulo3 from "@/components/SubTitle3.vue";
import Back from "@/components/Back.vue";
export default {
  name: "HomeView",

  components: {
    Titulo,
    Subtitulo3,
    Back,
  },
  props: {
    boletos_ida: {required:false},
    boletos_vuelta: {required:false}
  },
  data: () => ({
    boleto: {descuento: null},
    descuento: [],
    selectDescuento: [],
    selectedOption: '',
    descuentoAplicado: true,
    descuentoAnterior: null,
    ocultar: false,
    nombre: "",
    boletos: null,
    boletos_ida_final: null,
    descuentoAplicado: false,
    boletosUpdate: false
  }),
  async created() {
    this.boletos = JSON.parse(this.boletos_vuelta);
    for(let i=0; i < this.boletos.length; i++){
      this.boletos[i].check = false;
    }
    Promise.all([this.obtenerDescuento(), this.validar()]).then(function () {});
    this.boletosUpdate = true;
  },
  mounted: function() {
    this.validaChecks();
  },
  watch: {
    boletos_ida: {
      handler: 'validaChecks',
      inmediate: false,
      deep: true
    }
  },
  methods: {
    validaChecks(data){
      if(data != undefined){
        if(data.length > 60){
          this.boletos_ida_final = JSON.parse(data);
        }else{
          this.boletos_ida_final = data;
        }
      }
      this.boletosUpdate = false;
      if(this.boletos_ida_final != undefined && this.boletos_ida_final != null){
        for(let i=0; i < this.boletos_ida_final.length; i++){
          if(this.boletos_ida_final[i].check == true){
            //this.boletos[i].check = true;
            if(this.boletos_ida_final[i].nombre_pasajero != undefined){
              this.boletos[i].nombre_pasajero = this.boletos_ida_final[i].nombre_pasajero;
            }
            if(this.boletos_ida_final[i].edad !== undefined){
              this.boletos[i].edad = this.boletos_ida_final[i].edad;
            }
            if(this.boletos_ida_final[i].descuento !== null){
              this.boletos[i].descuento = this.boletos_ida_final[i].descuento;
            }
          }else{
            this.boletos[i].check = false;
            if(this.boletos_ida_final[i].nombre_pasajero !== undefined){
              this.boletos[i].nombre_pasajero = "";
            }
            if(this.boletos_ida_final[i].edad !== undefined){
              this.boletos[i].edad = "";
            }
            if(this.boletos_ida_final[i].descuento !== null){
              this.boletos[i].descuento = null;
            }
          }
      }
      this.saveToLocalStorage();
      }
      this.boletosUpdate = true;
    },
    async obtenerDescuento() {
      const id = localStorage.getItem('subcorrida_vuelta_id');
      let config = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        },
      };
      axios
        .get(`/descuentos/listActive`+'?subcorrida_id='+id, config)
        .then((response) => {
          this.descuento = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async saveToLocalStorage() {
      //localStorage.setItem("boleto_vuelta", JSON.stringify(this.boletos));
      this.$emit('eventBoletosVuelta', JSON.stringify(this.boletos));
    },
    
    validar() {
      const value = parseInt(localStorage.getItem("value"));
      if (value === 0) {
        this.ocultar = false;
      } else {
        this.ocultar = true;
      }
    },
    showDescuento() {
      if (this.descuento === null) {
      } else {
      }
    },
    async validarDescuento(index) {
      const montoIda = parseFloat(localStorage.getItem("monto_ida"));
      if (
        this.boletos[index].descuentoAplicado &&
        this.boletos[index].descuento === this.boletos[index].descuentoAnterior
      ) {
        return;
      }
          
      const tipo = this.boletos[index].descuento.tipo_valor;
      const valor = this.boletos[index].descuento.valor;
      const precio = this.boletos[index].precio_lista;
      if (tipo == "%") {
        const Porcentual = valor / 100;
        const descuento = parseFloat((Porcentual * precio).toFixed(2));
        const valorDescuento = parseFloat(precio) - parseFloat(descuento);
        this.boletos[index].monto_descuento = descuento;
        this.boletos[index].monto_total_pagar = valorDescuento;
        this.boletos[index].descuentoAplicado = true;
        const total = this.boletos.reduce((acumulador, boleto) => {
          return acumulador + boleto.monto_total_pagar;
        }, 0);
        localStorage.setItem("monto_ida", total);
      } else {
        const valorDescuento = parseFloat(precio) - parseFloat(valor);
        this.boletos[index].monto_descuento = valor;
        this.boletos[index].monto_total_pagar = valorDescuento
        const total = this.boletos.reduce((acumulador, boleto) => {
          return acumulador + boleto.monto_total_pagar;
        }, 0);
        const  totalVuelta = montoIda + valorDescuento;

        this.boletos[index].descuentoAplicado = true;
      }
      this.saveToLocalStorage();
    },
  },
};
</script>

<style scoped lang="less">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@media screen and (max-width: 420px) {
  h1 {
    font-family: "Montserrat", sans-serif;
    margin-top: 10px;
    font-size: 20px;
    margin-right: 55%;
    font-weight: 600;
    color: #1a2b4b;
  }
  h2 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    margin-top: 0px;
    margin-left: -19%;
    margin-right: 5%;
    font-size: 15px;
  }
  h3 {
    margin-top: 6%;
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    margin-left: 20px;
    font-size: 15px;
  }

  .titulo {
    font-family: "Montserrat";
    margin-left: -79px;
    font-size: 24px;
  }

  .clase {
    margin-top: -14px;
    margin-left: 5%;
    font-size: 14px;
  }
  .checkbox {
    margin-left: 100%;
    margin-top: -14%;
  }
  #checkbox {
    margin-top: -60px;
    margin-left: 10px;
    width: 22px;
    height: 20px;
    border: 1px solid #1e5180;
  }
  input[type="Nombre"] {
    margin-top: 20px;
    margin-left: 5%;
    text-indent: 30px;
    box-shadow: 1px 1px 1px;
    height: 82%;
    width: 340px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[placeholder] {
    font-size: 20px;
  }
  .columna {
    margin-left: 5%;
    font-family: "Montserrat";
    margin-top: 28px;
    font-size: 20px;
  }
  input[type="edad"] {
    margin-left: 5%;
    margin-top: 20px;
    text-indent: 20px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 70px;
    background-color: rgba(248, 250, 252, 255);
    border: 1px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
    margin-bottom: 30px;
  }

  .descuento {
    margin-top: 20px;
    margin-left: 4%;
    text-indent: 20px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 40%;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  select {
    font-family: "Montserrat";
    height: 50px;
    width: 80%;
    margin-top: -1%;
    font-weight: 400;
    font-size: 18px;
    margin-left: -15%;
  }

  .icon-rtl {
    background: url("https://cdn-icons-png.flaticon.com/512/5940/5940484.png")
      no-repeat right;
    background-size: 20px;
    margin-left: -25%;
  }
}
@media screen and (min-width: 421px) and (max-width: 767px) {
  h1 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    padding: 10px;
    margin-top: 10px;
    font-size: 20px;
    margin-right: 34%;
  }
  h2 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    margin-top: -30px;
    margin-left: -20%;
    font-size: 15px;
    margin-top: 2px;
  }
  h3 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;

    margin-left: 160px;
    font-size: 15px;
  }

  .clase {
    margin-top: -14px;
    margin-left: 12%;
    font-size: 14px;
  }
  .checkbox {
    margin-top: 10px;
    margin-left: 5%;
  }
  #checkbox {
    margin-top: -60px;

    width: 22px;
    height: 20px;
    border: 1px solid #1e5180;
  }
  input[type="Nombre"] {
    margin-top: 30px;
    margin-left: 30%;
    text-indent: 30px;
    box-shadow: 1px 1px 1px;
    height: 30%;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[placeholder] {
    font-size: 20px;
  }
  .columna {
    margin-left: -40%;
    font-family: "Montserrat";
    margin-top: 16%;
    font-size: 20px;
  }
  input[type="edad"] {
    margin-left: 5%;
    margin-top: 14%;
    text-indent: 20px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 70px;
    background-color: rgba(248, 250, 252, 255);
    border: 1px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  .descuento {
    margin-top: 14%;
    margin-left: 4%;
    text-indent: 20px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 30%;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  select {
    font-family: "Montserrat";
    height: 50px;
    width: 80%;
    margin-top: -1%;
    font-weight: 400;
    font-size: 18px;
    margin-left: -15%;
  }

  .icon-rtl {
    background: url("https://cdn-icons-png.flaticon.com/512/5940/5940484.png")
      no-repeat right;
    background-size: 20px;
    margin-left: -25%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  h1 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    padding: 10px;
    margin-top: 50px;
    font-size: 20px;
    margin-right: 26%;
  }
  h2 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    margin-top: -30px;
    margin-left: -11.5%;
    font-size: 15px;
    margin-top: 2px;
  }
  h3 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    margin-left: 218px;
    font-size: 15px;
  }

  .clase {
    margin-top: -14px;
    margin-left: 12%;
    font-size: 14px;
  }
  .checkbox {
    margin-top: -30px;
    margin-left: 104%;
  }
  #checkbox {
    margin-top: -60px;
    width: 22px;
    height: 20px;
    border: 1px solid #1e5180;
  }
  input[type="Nombre"] {
    margin-top: 25px;
    margin-left: 57%;
    text-indent: 30px;
    box-shadow: 1px 1px 1px;
    height: 30%;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[placeholder] {
    font-size: 20px;
  }
  .columna {
    margin-left: -21%;
    font-family: "Montserrat";
    margin-top: 14%;
    font-size: 20px;
  }
  input[type="edad"] {
    margin-left: 5%;
    margin-top: 12.5%;
    text-indent: 20px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 70px;
    background-color: rgba(248, 250, 252, 255);
    border: 1px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  .descuento {
    margin-top: 15%;
    margin-left: 4%;
    text-indent: 20px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 20%;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  select {
    font-family: "Montserrat";
    height: 50px;
    width: 80%;
    margin-top: -1%;
    font-weight: 400;
    font-size: 18px;
    margin-left: -15%;
  }

  .icon-rtl {
    background: url("https://cdn-icons-png.flaticon.com/512/5940/5940484.png")
      no-repeat right;
    background-size: 20px;
    margin-left: -25%;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1365px) {
  h1 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    padding: 10px;
    margin-top: 40px;
    font-size: 20px;
    margin-right: 29.5%;
  }
  h2 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    margin-top: 20px;
    margin-left: -22%;
    font-size: 15px;
    margin-top: 2px;
  }
  h3 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    margin-top: 25px;
    margin-left: 395px;
    font-size: 15px;
  }

  .clase {
    margin-top: -14px;
    margin-left: 12%;
    font-size: 14px;
  }
  .checkbox {
    margin-top: -30px;
    margin-left: 103%;
  }
  #checkbox {
    margin-top: -60px;
    width: 22px;
    height: 20px;
    border: 1px solid #1e5180;
  }
  input[type="Nombre"] {
    margin-top: 25px;
    margin-left: 235px;
    text-indent: 30px;
    box-shadow: 1px 1px 1px;
    height: 25%;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[placeholder] {
    font-size: 20px;
  }
  .columna {
    margin-left: -377px;
    font-family: "Montserrat";
    margin-top: 10%;
    font-size: 20px;
  }
  input[type="edad"] {
    margin-left: 5%;
    margin-top: 9%;
    text-indent: 20px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 70px;
    background-color: rgba(248, 250, 252, 255);
    border: 1px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  .descuento {
    margin-top: 15.3%;
    margin-left: -18.5%;
    text-indent: 20px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 20%;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  select {
    font-family: "Montserrat";
    height: 50px;
    width: 80%;
    margin-top: -1%;
    font-weight: 400;
    font-size: 18px;
    margin-left: -15%;
  }

  .icon-rtl {
    background: url("https://cdn-icons-png.flaticon.com/512/5940/5940484.png")
      no-repeat right;
    background-size: 20px;
    margin-left: -25%;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1439px) {
  h1 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    padding: 10px;
    margin-top: 40px;
    font-size: 20px;
    margin-right: 19%;
  }
  h2 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    margin-top: 20px;
    margin-left: -13.85%;
    font-size: 15px;
    margin-top: 2px;
  }
  h3 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    margin-top: 25px;
    margin-left: 395px;
    font-size: 15px;
  }

  .clase {
    margin-top: -14px;
    margin-left: 12%;
    font-size: 14px;
  }
  .checkbox {
    margin-top: -30px;
    margin-left: 103%;
  }
  #checkbox {
    margin-top: -60px;
    width: 22px;
    height: 20px;
    border: 1px solid #1e5180;
  }
  input[type="Nombre"] {
    margin-top: 25px;
    margin-left: 395px;
    text-indent: 30px;
    box-shadow: 1px 1px 1px;
    height: 25%;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[placeholder] {
    font-size: 20px;
  }
  .columna {
    margin-left: -377px;
    font-family: "Montserrat";
    margin-top: 10%;
    font-size: 20px;
  }
  input[type="edad"] {
    margin-left: 5%;
    margin-top: 9%;
    text-indent: 20px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 70px;
    background-color: rgba(248, 250, 252, 255);
    border: 1px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  .descuento {
    margin-top: 15%;
    margin-left: -15.5%;
    text-indent: 20px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 20%;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  select {
    font-family: "Montserrat";
    height: 50px;
    width: 80%;
    margin-top: -1%;
    font-weight: 400;
    font-size: 18px;
    margin-left: -15%;
  }

  .icon-rtl {
    background: url("https://cdn-icons-png.flaticon.com/512/5940/5940484.png")
      no-repeat right;
    background-size: 20px;
    margin-left: -25%;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1919px) {
  h1 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    padding: 10px;
    margin-top: 40px;
    font-size: 20px;
    margin-right: 18.5%;
  }
  h2 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    margin-top: 20px;
    margin-left: -12.85%;
    font-size: 15px;
    margin-top: 2px;
  }
  h3 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    margin-top: 25px;
    margin-left: 395px;
    font-size: 15px;
  }

  .clase {
    margin-top: -14px;
    margin-left: 12%;
    font-size: 14px;
  }
  .checkbox {
    margin-top: -30px;
    margin-left: 103%;
  }
  #checkbox {
    margin-top: -60px;
    width: 22px;
    height: 20px;
    border: 1px solid #1e5180;
  }
  input[type="Nombre"] {
    margin-top: 25px;
    margin-left: 395px;
    text-indent: 30px;
    box-shadow: 1px 1px 1px;
    height: 25%;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[placeholder] {
    font-size: 20px;
  }
  .columna {
    margin-left: -377px;
    font-family: "Montserrat";
    margin-top: 10%;
    font-size: 20px;
  }
  input[type="edad"] {
    margin-left: 5%;
    margin-top: 9%;
    text-indent: 20px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 70px;
    background-color: rgba(248, 250, 252, 255);
    border: 1px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  .descuento {
    margin-top: 15%;
    margin-left: -15.5%;
    text-indent: 20px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 20%;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  select {
    font-family: "Montserrat";
    height: 50px;
    width: 80%;
    margin-top: -1%;
    font-weight: 400;
    font-size: 18px;
    margin-left: -15%;
  }

  .icon-rtl {
    background: url("https://cdn-icons-png.flaticon.com/512/5940/5940484.png")
      no-repeat right;
    background-size: 20px;
    margin-left: -25%;
  }
}
@media screen and (min-width: 1920px){
  h1 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    padding: 10px;
    margin-top: 50px;
    font-size: 20px;
    margin-right: 11.7%;
  }
  h2 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    margin-top: 20px;
    margin-left: -8.4%;
    font-size: 15px;
    margin-top: 2px;
  }
  h3 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    margin-top: 25px;
    margin-left: 700px;
    font-size: 15px;
  }

  .clase {
    margin-top: -14px;
    margin-left: 12%;
    font-size: 14px;
  }
  .checkbox {
    margin-top: -30px;
    margin-left: 102%;
  }
  #checkbox {
    margin-top: -60px;
    width: 22px;
    height: 20px;
    border: 1px solid #1e5180;
  }
  input[type="Nombre"] {
    margin-top: 25px;
    margin-left: 705px;
    text-indent: 30px;
    box-shadow: 1px 1px 1px;
    height: 25%;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[placeholder] {
    font-size: 20px;
  }
  .columna {
    margin-left: -377px;
    font-family: "Montserrat";
    margin-top: 7%;
    font-size: 20px;
  }
  input[type="edad"] {
    margin-left: 2%;
    margin-top: 6%;
    text-indent: 20px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 70px;
    background-color: rgba(248, 250, 252, 255);
    border: 1px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  .descuento {
    margin-top: 10%;
    margin-left: -9%;
    text-indent: 20px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 20%;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  select {
    font-family: "Montserrat";
    height: 50px;
    width: 80%;
    margin-top: -1%;
    font-weight: 400;
    font-size: 18px;
    margin-left: -15%;
  }

  .icon-rtl {
    background: url("https://cdn-icons-png.flaticon.com/512/5940/5940484.png")
      no-repeat right;
    background-size: 20px;
    margin-left: -25%;
  }
}
</style>