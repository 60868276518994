var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body",class:_vm.bodyVariable},[(!_vm.iframe)?_c('div',{staticClass:"row flex items-center"},[_c('div',{staticClass:"col-8"}),_vm._m(0)]):_vm._e(),(!_vm.iframe)?_c('div',{staticClass:"row mb-10 bienvenido",attrs:{"id":"appp"}},[_vm._m(1)]):_vm._e(),_c('div',{staticClass:"pt-0 mb-10 mt-0"},[_c('div',{staticClass:"grid place-items-center",class:_vm.sencilloredondo},[_c('div',{staticClass:"switch-button",class:['largo'],attrs:{"id":"switch-button"},on:{"click":function($event){return _vm.click()}}},[_c('div',{staticClass:"switch-item",on:{"click":function($event){_vm.clickRedondo();
            _vm.hide();}}},[_vm._v(" Redondo ")]),_c('div',{staticClass:"switch-item",on:{"click":function($event){_vm.clickSencillo();
            _vm.hide();}}},[_vm._v(" Sencillo ")])])])]),_c('div',{staticClass:"container",class:_vm.origenVariable,attrs:{"data-app":""}},[_c('div',{staticClass:"vamos text-left"},[_c('h4',{class:_vm.vamosVariable},[_vm._v("¿A dónde vamos?")])]),_c('div',{staticClass:"place-items-center mt-0"},[_c('div',{staticClass:"box"},[_vm._m(2),_c('div',{staticClass:"input1"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"","sm":"13"}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectida),expression:"selectida"}],staticClass:"icon",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectida=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"disabled":"","value":""}},[_vm._v("Selecciona un origen")]),_vm._l((_vm.ida),function(item){return _c('option',{key:item.id2,domProps:{"value":[
                    item.id,
                    item.attributes.nombre,
                    item.attributes.ciudad.attributes.nombre,
                    item.attributes.abreviatura,
                  ]}},[(item.terminal != undefined)?[_vm._v(" "+_vm._s(item.terminal)+" ")]:[_vm._v(" "+_vm._s(item.attributes.ciudad.attributes.nombre)+" ")]],2)})],2)])],1)],1)],1),_vm._m(3),_vm._m(4),_c('div',{staticClass:"input1"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"","sm":"14"}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectvuelta),expression:"selectvuelta"}],staticClass:"icon",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectvuelta=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"disabled":"","value":""}},[_vm._v("Selecciona un destino")]),_vm._l((_vm.ida),function(item){return _c('option',{key:item.id,domProps:{"value":[
                    item.id,
                    item.attributes.nombre,
                    item.attributes.ciudad.attributes.nombre,
                    item.attributes.abreviatura,
                  ]}},[(item.terminal != undefined)?[_vm._v(" "+_vm._s(item.terminal)+" ")]:[_vm._v(" "+_vm._s(item.attributes.ciudad.attributes.nombre)+" ")]],2)})],2)])],1)],1)],1)])])]),_c('div',{staticClass:"grid grid-cols-8"},[_c('div',{staticClass:"col-start-4 2xl:-mr-2 xl:mr-6 lg:-ml-32 -ml-48"},[_c('date-picker',{attrs:{"id":"input-ida","name":"fecha_ida","value-type":"date","lang":_vm.lang,"placeholder":"Ida","disabled-date":_vm.notBeforeToday,"format":"YYYY-MM-DD","confirm":true,"confirm-text":'ACEPTAR'},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('i',{attrs:{"slot":"icon-calendar"},slot:"icon-calendar"},[_c('div',{staticClass:"icon-calendario"},[_c('img',{attrs:{"src":"img/Calendario.png"}})])])])],1),_c('div',{staticClass:"2xl:ml-8 xl:ml-2 lg:ml-0 -ml-12",class:_vm.pickerVueltaVariable,attrs:{"id":"myDIV"}},[_c('date-picker',{attrs:{"id":"input-vuelta","name":"fecha_vuelta","value-type":"date","lang":_vm.lang,"placeholder":"Vuelta","disabled-date":_vm.notBeforeToday,"confirm":true,"confirm-text":'ACEPTAR'},model:{value:(_vm.date2),callback:function ($$v) {_vm.date2=$$v},expression:"date2"}},[_c('i',{attrs:{"slot":"icon-calendar"},slot:"icon-calendar"},[_c('div',{staticClass:"icon-calendario"},[_c('img',{attrs:{"src":"img/Calendario.png"}})])])])],1)]),_c('button',{staticClass:"mt-4",on:{"click":function($event){return _vm.validacion();}}},[_c('App-button',{attrs:{"buttonText":"Buscar   "}})],1),(_vm.error)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.error_msg)+" ")]):_vm._e(),(!_vm.iframe)?_c('div',{staticClass:"registrate"},[(_vm.noSesion)?_c('router-link',{attrs:{"to":"registrate"}},[_c('h4',[_vm._v("Regístrate")])]):_vm._e(),(_vm.noSesion)?_c('router-link',{attrs:{"to":"login"}},[_c('h4',[_vm._v("Inicia Sesión")])]):_vm._e()],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4 flex"},[_c('a',{attrs:{"href":"https://www.facebook.com/apoloplatinum","target":"_blank"}},[_c('img',{staticStyle:{"padding-right":"7px !important"},attrs:{"src":"/images/facebook.png","width":"40"}})]),_c('a',{attrs:{"href":"https://www.instagram.com/apoloplatinum","target":"_blank"}},[_c('img',{staticStyle:{"padding-right":"7px !important"},attrs:{"src":"/images/instagram.png","width":"40"}})]),_c('a',{attrs:{"href":"https://www.youtube.com/@apoloplatinum6490","target":"_blank"}},[_c('img',{staticStyle:{"padding-right":"7px !important"},attrs:{"src":"/images/youtube.png","width":"40"}})]),_c('a',{attrs:{"href":"https://www.tiktok.com/@apoloplatinum","target":"_blank"}},[_c('img',{staticStyle:{"padding-right":"7px !important"},attrs:{"src":"/images/tiktok.png","width":"40"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 text-center"},[_c('h1',[_vm._v("Tu mejor viaje "),_c('br'),_vm._v("Bienvenido")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"origen"},[_c('h5',[_vm._v("Origen")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hr"},[_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Destino"},[_c('h5',[_vm._v("Destino")])])
}]

export { render, staticRenderFns }