<template>
  <div>
    <div class="pagar">
      <Titulo Titulo="Pagar con" />
    </div>
    <v-col>
      <v-row>
        <div class="paypal">
          <Paypal :boletos_ida="boletos_ida" :boletos_vuelta="boletos_vuelta" />
        </div>

        <div id="button" class="openpay">
          <div @click="iniciar();"  class="imagenopen">
            <img src="img/open.png" width="140px"/>
          </div>
        </div>
        <div id="overlay"></div>
        <div id="popup">
          <div class="popupcontent">
            <v-row>
              <div class="credito">
                <div class="tarjeta">
                  <img src="img/cards1.png" />
                </div>
                <router-link to="/pagar">
                  <div class="pagotarjeta">
                    <p>Pago en Tarjeta</p>
                  </div>
                </router-link>
              </div>
            </v-row>
            <v-row>
              <div class="servicio">
                <router-link to="/recibo">
                  <img class="tarjeta2" src="images/paynet_logo.png" />
                  <div class="tienda">
                    <p>Pago en Tiendas</p>
                  </div>
                </router-link>
              </div>
            </v-row>
          </div>
          <div class="popupcontrols">
            <span id="popupclose">
              <div class="cerrar">
                <i class="material-icons"> close </i>
              </div>
            </span>
          </div>
        </div>
      </v-row>
    </v-col>
  </div>
</template>

<style scoped lang="less">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@media screen and (max-width: 480px) {
  .pagar {
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: bold;
    margin-left: -10%;
    margin-top: 0%;
  }
  .paypal {
    margin-left: 8%;
    
  }
  .imagen {
    margin-top: -27%;
    margin-left: 9%;
  }
  .openpay {
    font-family: "Montserrat";
    font-weight: 700;
    box-shadow: -2px 3px 3px 1px rgba(208, 215, 230, 255);
    font-size: 18px;
    background-color: #0f68a1;
    color: rgb(255, 255, 255);
    border: 1px solid #0f68a1;
    border-radius: 30px;
    height: 40px;
    width: 140px;
    margin-left: 6%;
    margin-top: -0%;
  }
  .imagenopen {
    margin-top: -3%;
    margin-left: 3%;

  }

  #overlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    background: #999;
    width: 0%;
    height: 0%;
    opacity: 0.8;
    z-index: 100;
  }
  #popup {
    display: none;
    position: absolute;
    top: 1100px;
    left: 35%;
    background: #fff;
    width: 270px;
    height: 150px;
    margin-left: -30px; /*Half the value of width to center div*/
    margin-top: -100px; /*Half the value of height to center div*/
    z-index: 200;
    border-radius: 20px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
  }
  #popupclose {
    float: right;
    padding: 10px;
    cursor: pointer;
  }
  .popupcontent {
    padding: 10px;
  }
  .credito {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 18px;
    color: black;
    margin-right: 0%;
    margin-left: 22%;
  }
  .servicio {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 18px;
    color: black;
    margin-right: -10%;
    margin-left: 23%;
  }

  .cerrar {
    margin-top: 40%;
    margin-left: -25%;
  }
  .tarjeta {
    width: 150px;
  }
  .tarjeta2 {
    width: 100px;
  }
  .tienda {
    font-family: "Montserrat";
    box-shadow: -2px 3px 3px 1px rgba(208, 215, 230, 255);
    font-size: 18px;
    background-color: #0f68a1;
    color: rgb(255, 255, 255);
    border: 1px solid #0f68a1;
    border-radius: 10px;
    height: 35px;
    width: 190px;
    font-weight: 500;
    margin-left: -20px;
    margin-top: 5%;
  }
  .pagotarjeta {
    font-family: "Montserrat";
    box-shadow: -2px 3px 3px 1px rgba(208, 215, 230, 255);
    font-size: 18px;
    background-color: #0f68a1;
    color: rgb(255, 255, 255);
    border: 1px solid #0f68a1;
    border-radius: 10px;
    height: 35px;
    width: 190px;
    font-weight: 500;
    margin-left: -20px;
    margin-top: 5%;
  }
}
@media screen and (min-width: 481px) and (max-width: 679px) {
  .pagar {
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: bold;
    margin-left: -10%;
    margin-top: 0%;
  }
  .paypal {
    margin-left: 20%;
    
  }
  .imagen {
    margin-top: -27%;
    margin-left: 9%;
  }
  .openpay {
    font-family: "Montserrat";
    font-weight: 700;
    box-shadow: -2px 3px 3px 1px rgba(208, 215, 230, 255);
    font-size: 18px;
    background-color: #0f68a1;
    color: rgb(255, 255, 255);
    border: 1px solid #0f68a1;
    border-radius: 30px;
    height: 40px;
    width: 140px;
    margin-left: 5%;
    margin-top: -0%;
  }
  .imagenopen {
    margin-top: -3%;
    margin-left: 3%;
  }

  #overlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    background: #999;
    width: 0%;
    height: 0%;
    opacity: 0.8;
    z-index: 100;
  }
  #popup {
    display: none;
    position: absolute;
    top: 120%;
    left: 50%;
    background: #fff;
    width: 270px;
    height: 150px;
    margin-left: -30px; /*Half the value of width to center div*/
    margin-top: -100px; /*Half the value of height to center div*/
    z-index: 200;
    border-radius: 20px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
  }
  #popupclose {
    float: right;
    padding: -130px;
    cursor: pointer;
  }
  .popupcontent {
    padding: 10px;
  }
  .credito {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 18px;
    color: black;
    margin-right: 0%;
    margin-left: 22%;
  }
  .servicio {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 18px;
    color: black;
    margin-right: -10%;
    margin-left: 23%;
  }

  .cerrar {
    margin-top: 40%;
    margin-left: -25%;
  }
  .tarjeta {
    width: 150px;
  }
  .tarjeta2 {
    width: 100px;
  }
  .tienda {
    font-family: "Montserrat";
    box-shadow: -2px 3px 3px 1px rgba(208, 215, 230, 255);
    font-size: 18px;
    background-color: #0f68a1;
    color: rgb(255, 255, 255);
    border: 1px solid #0f68a1;
    border-radius: 10px;
    height: 35px;
    width: 190px;
    font-weight: 500;
    margin-left: -20px;
    margin-top: 5%;
  }
  .pagotarjeta {
    font-family: "Montserrat";
    box-shadow: -2px 3px 3px 1px rgba(208, 215, 230, 255);
    font-size: 18px;
    background-color: #0f68a1;
    color: rgb(255, 255, 255);
    border: 1px solid #0f68a1;
    border-radius: 10px;
    height: 35px;
    width: 190px;
    font-weight: 500;
    margin-left: -20px;
    margin-top: 5%;
  }
}
@media screen and (min-width: 680px) and (max-width: 800px) {
  .pagar {
    font-family: "Montserrat";
    font-size: 22px;
    font-weight: bold;
    margin-left: -1%;
    margin-top: 0%;
  }
  .paypal {
    margin-left: 29%;
    
  }
  .imagen {
    margin-top: -27%;
    margin-left: 9%;
  }
  .openpay {
    font-family: "Montserrat";
    font-weight: 700;
    box-shadow: -2px 3px 3px 1px rgba(208, 215, 230, 255);
    font-size: 18px;
    background-color: #0f68a1;
    color: rgb(255, 255, 255);
    border: 1px solid #0f68a1;
    border-radius: 30px;
    height: 40px;
    width: 140px;
    margin-left: 5%;
    margin-top: -0%;
  }
 .imagenopen {
    margin-top: -3%;
    margin-left: 3%;
  }

  #overlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    background: #999;
    width: 0%;
    height: 0%;
    opacity: 0.8;
    z-index: 100;
  }
  #popup {
    display: none;
    position: absolute;
    top: 130%;
    left: 50%;
    background: #fff;
    width: 270px;
    height: 150px;
    margin-left: -30px; /*Half the value of width to center div*/
    margin-top: -100px; /*Half the value of height to center div*/
    z-index: 200;
    border-radius: 20px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
  }
  #popupclose {
    float: right;
    padding: 10px;
    cursor: pointer;
  }
  .popupcontent {
    padding: 10px;
  }
  .credito {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 18px;
    color: black;
    margin-right: 0%;
    margin-left: 22%;
  }
  .servicio {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 18px;
    color: black;
    margin-right: -10%;
    margin-left: 23%;
  }

  .cerrar {
    margin-top: 40%;
    margin-left: -25%;
  }
  .tarjeta {
    width: 150px;
  }
  .tarjeta2 {
    width: 100px;
  }
  .tienda {
    font-family: "Montserrat";
    box-shadow: -2px 3px 3px 1px rgba(208, 215, 230, 255);
    font-size: 18px;
    background-color: #0f68a1;
    color: rgb(255, 255, 255);
    border: 1px solid #0f68a1;
    border-radius: 10px;
    height: 35px;
    width: 190px;
    font-weight: 500;
    margin-left: -20px;
    margin-top: 5%;
  }
  .pagotarjeta {
    font-family: "Montserrat";
    box-shadow: -2px 3px 3px 1px rgba(208, 215, 230, 255);
    font-size: 18px;
    background-color: #0f68a1;
    color: rgb(255, 255, 255);
    border: 1px solid #0f68a1;
    border-radius: 10px;
    height: 35px;
    width: 190px;
    font-weight: 500;
    margin-left: -20px;
    margin-top: 5%;
  }
}
@media screen and (min-width: 801px) and (max-width: 1200px) {
  .pagar {
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: bold;
    margin-left: -10%;
    margin-top: 0%;
  }
  .paypal {
    margin-left: 30%;
  }
  .imagen {
    margin-top: -27%;
    margin-left: 9%;
  }
  .openpay {
    font-family: "Montserrat";
    font-weight: 700;
    box-shadow: -2px 3px 3px 1px rgba(208, 215, 230, 255);
    font-size: 18px;
    background-color: #0f68a1;
    color: rgb(255, 255, 255);
    border: 1px solid #0f68a1;
    border-radius: 30px;
    height: 40px;
    width: 140px;
    margin-left: 5%;
    margin-top: -0%;
  }
  .imagenopen {
    margin-top: -3%;
    margin-left: 3%;
  }
  #overlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    background: #999;
    width: 0%;
    height: 0%;
    opacity: 0.8;
    z-index: 100;
  }
  #popup {
    display: none;
    position: absolute;
    top: 128%;
    left: 50%;
    background: #fff;
    width: 270px;
    height: 150px;
    margin-left: -30px; /*Half the value of width to center div*/
    margin-top: -100px; /*Half the value of height to center div*/
    z-index: 200;
    border-radius: 20px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
  }
  #popupclose {
    float: right;
    padding: 10px;
    cursor: pointer;
  }
  .popupcontent {
    padding: 10px;
  }
  .credito {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 18px;
    color: black;
    margin-right: 0%;
    margin-left: 22%;
  }
  .servicio {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 18px;
    color: black;
    margin-right: -10%;
    margin-left: 23%;
  }
  .cerrar {
    margin-top: 40%;
    margin-left: -25%;
  }
  .tarjeta {
    width: 150px;
  }
  .tarjeta2 {
    width: 100px;
  }
  .tienda {
    font-family: "Montserrat";
    box-shadow: -2px 3px 3px 1px rgba(208, 215, 230, 255);
    font-size: 18px;
    background-color: #0f68a1;
    color: rgb(255, 255, 255);
    border: 1px solid #0f68a1;
    border-radius: 10px;
    height: 35px;
    width: 190px;
    font-weight: 500;
    margin-left: -20px;
    margin-top: 5%;
  }
  .pagotarjeta {
    font-family: "Montserrat";
    box-shadow: -2px 3px 3px 1px rgba(208, 215, 230, 255);
    font-size: 18px;
    background-color: #0f68a1;
    color: rgb(255, 255, 255);
    border: 1px solid #0f68a1;
    border-radius: 10px;
    height: 35px;
    width: 190px;
    font-weight: 500;
    margin-left: -20px;
    margin-top: 5%;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1365px) {
  .pagar {
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: bold;
    margin-left: -5%;
    margin-top: 0%;
  }
  .paypal {
    margin-left: 33.5%;
  }
  .imagen {
    margin-top: -27%;
    margin-left: 9%;
  }
  .openpay {
    font-family: "Montserrat";
    font-weight: 700;
    box-shadow: -2px 3px 3px 1px rgba(208, 215, 230, 255);
    font-size: 18px;
    background-color: #0f68a1;
    color: rgb(255, 255, 255);
    border: 1px solid #0f68a1;
    border-radius: 30px;
    height: 40px;
    width: 140px;
    margin-left: 5%;
    margin-top: -0%;
  }
  .imagenopen {
    margin-top: -3%;
    margin-left: 3%;
  }
  #overlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    background: #999;
    width: 0%;
    height: 0%;
    opacity: 0.8;
    z-index: 100;
  }
  #popup {
    display: none;
    position: absolute;
    top: 128%;
    left: 50%;
    background: #fff;
    width: 270px;
    height: 150px;
    margin-left: -30px; /*Half the value of width to center div*/
    margin-top: -100px; /*Half the value of height to center div*/
    z-index: 200;
    border-radius: 20px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
  }
  #popupclose {
    float: right;
    padding: 10px;
    cursor: pointer;
  }
  .popupcontent {
    padding: 10px;
  }
  .credito {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 18px;
    color: black;
    margin-right: 0%;
    margin-left: 22%;
  }
  .servicio {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 18px;
    color: black;
    margin-right: -10%;
    margin-left: 23%;
  }
  .cerrar {
    margin-top: 40%;
    margin-left: -25%;
  }
  .tarjeta {
    width: 150px;
  }
  .tarjeta2 {
    width: 100px;
  }
  .tienda {
    font-family: "Montserrat";
    box-shadow: -2px 3px 3px 1px rgba(208, 215, 230, 255);
    font-size: 18px;
    background-color: #0f68a1;
    color: rgb(255, 255, 255);
    border: 1px solid #0f68a1;
    border-radius: 10px;
    height: 35px;
    width: 190px;
    font-weight: 500;
    margin-left: -20px;
    margin-top: 5%;
  }
  .pagotarjeta {
    font-family: "Montserrat";
    box-shadow: -2px 3px 3px 1px rgba(208, 215, 230, 255);
    font-size: 18px;
    background-color: #0f68a1;
    color: rgb(255, 255, 255);
    border: 1px solid #0f68a1;
    border-radius: 10px;
    height: 35px;
    width: 190px;
    font-weight: 500;
    margin-left: -20px;
    margin-top: 5%;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1439px) {
  .pagar {
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: bold;
    margin-left: -5%;
    margin-top: 0%;
  }
  .paypal {
    margin-left: 35.5%;
  }
  .imagen {
    margin-top: -27%;
    margin-left: 9%;
  }
  .openpay {
    font-family: "Montserrat";
    font-weight: 700;
    box-shadow: -2px 3px 3px 1px rgba(208, 215, 230, 255);
    font-size: 18px;
    background-color: #0f68a1;
    color: rgb(255, 255, 255);
    border: 1px solid #0f68a1;
    border-radius: 30px;
    height: 40px;
    width: 140px;
    margin-left: 5%;
    margin-top: -0%;
  }
  .imagenopen {
    margin-top: -3%;
    margin-left: 3%;
  }
  #overlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    background: #999;
    width: 0%;
    height: 0%;
    opacity: 0.8;
    z-index: 100;
  }
  #popup {
    display: none;
    position: absolute;
    top: 128%;
    left: 50%;
    background: #fff;
    width: 270px;
    height: 150px;
    margin-left: -30px; /*Half the value of width to center div*/
    margin-top: -100px; /*Half the value of height to center div*/
    z-index: 200;
    border-radius: 20px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
  }
  #popupclose {
    float: right;
    padding: 10px;
    cursor: pointer;
  }
  .popupcontent {
    padding: 10px;
  }
  .credito {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 18px;
    color: black;
    margin-right: 0%;
    margin-left: 22%;
  }
  .servicio {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 18px;
    color: black;
    margin-right: -10%;
    margin-left: 23%;
  }
  .cerrar {
    margin-top: 40%;
    margin-left: -25%;
  }
  .tarjeta {
    width: 150px;
  }
  .tarjeta2 {
    width: 100px;
  }
  .tienda {
    font-family: "Montserrat";
    box-shadow: -2px 3px 3px 1px rgba(208, 215, 230, 255);
    font-size: 18px;
    background-color: #0f68a1;
    color: rgb(255, 255, 255);
    border: 1px solid #0f68a1;
    border-radius: 10px;
    height: 35px;
    width: 190px;
    font-weight: 500;
    margin-left: -20px;
    margin-top: 5%;
  }
  .pagotarjeta {
    font-family: "Montserrat";
    box-shadow: -2px 3px 3px 1px rgba(208, 215, 230, 255);
    font-size: 18px;
    background-color: #0f68a1;
    color: rgb(255, 255, 255);
    border: 1px solid #0f68a1;
    border-radius: 10px;
    height: 35px;
    width: 190px;
    font-weight: 500;
    margin-left: -20px;
    margin-top: 5%;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1919px) {
  .pagar {
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: bold;
    margin-left: -5%;
    margin-top: 0%;
  }
  .paypal {
    margin-left: 35.5%;
  }
  .imagen {
    margin-top: -27%;
    margin-left: 9%;
  }
  .openpay {
    font-family: "Montserrat";
    font-weight: 700;
    box-shadow: -2px 3px 3px 1px rgba(208, 215, 230, 255);
    font-size: 18px;
    background-color: #0f68a1;
    color: rgb(255, 255, 255);
    border: 1px solid #0f68a1;
    border-radius: 30px;
    height: 40px;
    width: 140px;
    margin-left: 5%;
    margin-top: -0%;
  }
  .imagenopen {
    margin-top: -3%;
    margin-left: 3%;
  }
  #overlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    background: #999;
    width: 0%;
    height: 0%;
    opacity: 0.8;
    z-index: 100;
  }
  #popup {
    display: none;
    position: absolute;
    top: 128%;
    left: 50%;
    background: #fff;
    width: 270px;
    height: 150px;
    margin-left: -30px; /*Half the value of width to center div*/
    margin-top: -100px; /*Half the value of height to center div*/
    z-index: 200;
    border-radius: 20px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
  }
  #popupclose {
    float: right;
    padding: 10px;
    cursor: pointer;
  }
  .popupcontent {
    padding: 10px;
  }
  .credito {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 18px;
    color: black;
    margin-right: 0%;
    margin-left: 22%;
  }
  .servicio {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 18px;
    color: black;
    margin-right: -10%;
    margin-left: 23%;
  }
  .cerrar {
    margin-top: 40%;
    margin-left: -25%;
  }
  .tarjeta {
    width: 150px;
  }
  .tarjeta2 {
    width: 100px;
  }
  .tienda {
    font-family: "Montserrat";
    box-shadow: -2px 3px 3px 1px rgba(208, 215, 230, 255);
    font-size: 18px;
    background-color: #0f68a1;
    color: rgb(255, 255, 255);
    border: 1px solid #0f68a1;
    border-radius: 10px;
    height: 35px;
    width: 190px;
    font-weight: 500;
    margin-left: -20px;
    margin-top: 5%;
  }
  .pagotarjeta {
    font-family: "Montserrat";
    box-shadow: -2px 3px 3px 1px rgba(208, 215, 230, 255);
    font-size: 18px;
    background-color: #0f68a1;
    color: rgb(255, 255, 255);
    border: 1px solid #0f68a1;
    border-radius: 10px;
    height: 35px;
    width: 190px;
    font-weight: 500;
    margin-left: -20px;
    margin-top: 5%;
  }
}
@media screen and (min-width: 1920px)  {
  .pagar {
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: bold;
    margin-left: 0%;
    margin-top: 0%;
  }
  .paypal {
    margin-left: 40%;
    
  }
  .imagen {
    margin-top: -27%;
    margin-left: 9%;
  }
  .openpay {
    font-family: "Montserrat";
    font-weight: 700;
    box-shadow: -2px 3px 3px 1px rgba(208, 215, 230, 255);
    font-size: 18px;
    background-color: #0f68a1;
    color: rgb(255, 255, 255);
    border: 1px solid #0f68a1;
    border-radius: 30px;
    height: 40px;
    width: 140px;
    margin-left: 5%;
    margin-top: -0%;
  }
  .imagenopen {
    margin-top: -5%;
    margin-left: 11%;
    margin-right: 5%;
  }

  #overlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    background: #999;
    width: 0%;
    height: 0%;
    opacity: 0.8;
    z-index: 100;
  }
  #popup {
    display: none;
    position: absolute;
    top: 129%;
    left: 50%;
    background: #fff;
    width: 270px;
    height: 150px;
    margin-left: -30px; /*Half the value of width to center div*/
    margin-top: -100px; /*Half the value of height to center div*/
    z-index: 200;
    border-radius: 20px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
  }
  #popupclose {
    float: right;
    padding: 10px;
    cursor: pointer;
  }
  .popupcontent {
    padding: 10px;
  }
  .credito {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 18px;
    color: black;
    margin-right: 0%;
    margin-left: 22%;
  }
  .servicio {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 18px;
    color: black;
    margin-right: -10%;
    margin-left: 23%;
  }

  .cerrar {
    margin-top: 40%;
    margin-left: -25%;
  }
  .tarjeta {
    width: 150px;
  }
  .tarjeta2 {
    width: 100px;
  }
  .tienda {
    font-family: "Montserrat";
    box-shadow: -2px 3px 3px 1px rgba(208, 215, 230, 255);
    font-size: 18px;
    background-color: #0f68a1;
    color: rgb(255, 255, 255);
    border: 1px solid #0f68a1;
    border-radius: 10px;
    height: 35px;
    width: 190px;
    font-weight: 500;
    margin-left: -20px;
    margin-top: 5%;
  }
  .pagotarjeta {
    font-family: "Montserrat";
    box-shadow: -2px 3px 3px 1px rgba(208, 215, 230, 255);
    font-size: 18px;
    background-color: #0f68a1;
    color: rgb(255, 255, 255);
    border: 1px solid #0f68a1;
    border-radius: 10px;
    height: 35px;
    width: 190px;
    font-weight: 500;
    margin-left: -20px;
    margin-top: 5%;
  }
}
</style>


<script>
import Titulo from "@/components/title.vue";
import Paypal from "@/components/Paypal.vue";

export default {
  name: "HomeView",
  components: {
    Titulo,
    Paypal,
  },
  props:{
    boletos_ida: { required: false },
    boletos_vuelta: { required: false }
  },
  methods: {
    iniciar() {
      // Initialize Variables
      var closePopup = document.getElementById("popupclose");
      var overlay = document.getElementById("overlay");
      var popup = document.getElementById("popup");
      var button = document.getElementById("button");
      // Close Popup Event
      closePopup.onclick = function () {
        overlay.style.display = "none";
        popup.style.display = "none";
      };
      // Show Overlay and Popup
      button.onclick = function () {
        overlay.style.display = "block";
        popup.style.display = "block";
      };
    },
  },
};
</script>
